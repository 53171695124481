import React from "react"
import { Link } from "gatsby"
import { Button } from '@material-ui/core';
import Layout from "../components/layout"
import SEO from "../components/seo"

const tools = [
    //{title:"Spannungsteiler mit E-Reihen berechnen", description:"", link:"resistor-calculation-e-series", img: ""},
    { title: "E-Reihen Widerstand bestimmen", description: "", link: "/widerstand-e-reihe-bestimmen", img: "" },
]

const ToolboxPage = ({ data, location }) => {
    console.log(location.pathname)
    return (
        <Layout location={location} title="Toolbox">
            <SEO title="Toolbox" />
            <h1>Elektronische Werkzeugkiste</h1>
            {
                tools.map((tool, index) => (
                    <p>
                        <Button size="small" variant="contained" color="secondary">
                            <Link to={location.pathname + tool.link}>{tool.title}</Link>
                        </Button>
                    </p>
                ))
            }
        </Layout>
    )
}

export default ToolboxPage